import React from 'react';
import * as style from './style';

export default function TabWork() {
  return (
    <>
      <style.ContainerGeral>
        <>
          <style.TextTittle>FAÇA PARTE DA ALKA</style.TextTittle>
        </>
        <style.ContainerBox>
          <style.BoxInfo>
            <style.TittleBox>VAGAS</style.TittleBox>
            <style.SubTittleBox>ARTISTAS</style.SubTittleBox>
            <style.TextBox>
              Se você é artista e precisa dos nossos serviços para crescer no
              mercado musical.
            </style.TextBox>
          </style.BoxInfo>
          <style.BoxInfo>
            <style.TittleBox>VAGAS</style.TittleBox>
            <style.SubTittleBox>CRIADOR</style.SubTittleBox>
            <style.TextBox>
              Se você é criador de conteúdo e gostaria de fazer parte do nosso
              casting de ações.
            </style.TextBox>
          </style.BoxInfo>
          <style.BoxInfo>
            <style.TittleBox>VAGAS</style.TittleBox>
            <style.SubTittleBox>JOBS</style.SubTittleBox>
            <style.TextBox>
              Se você é profissional remoto e gostaria de fazer parte do nosso
              time.
            </style.TextBox>
          </style.BoxInfo>
        </style.ContainerBox>
      </style.ContainerGeral>
    </>
  );
}
