import React from 'react';
import * as style from './style';

export default function TabWork() {
  return (
    <>
      <style.ContainerGeral>
        <>
          <style.TextTittle>ALKA</style.TextTittle>
        </>
        <style.TextSubTittle>
          Nosso grupo vivencia o mercado da música, entretenimento e show
          business.
        </style.TextSubTittle>
        <style.ContainerBox>
          <style.BoxInfo>
            <style.IconConnectWithoutContact />
            <style.TextBox>
              Possuímos uma agência que gerencia a carreira digital do artista e
              influenciador.
            </style.TextBox>
          </style.BoxInfo>
          <style.BoxInfo>
            <style.IconTargetArrow />
            <style.TextBox>
              Nossos treinamentos capacitam pessoas para o mercado de
              entretenimento.{' '}
            </style.TextBox>
          </style.BoxInfo>
          <style.BoxInfo>
            <style.IconCodeBlock />
            <style.TextBox>
              Uma equipe expansível trabalhando remotamente através do nosso
              sistema.
            </style.TextBox>
          </style.BoxInfo>
          <style.BoxInfo>
            <style.IconApprovalApp />
            <style.TextBox>
              Monitoramos dados de plataformas e redes sociais dos nossos
              clientes.
            </style.TextBox>
          </style.BoxInfo>
          <style.BoxInfo>
            <style.IconApprovalApp />
            <style.TextBox>
              Monitoramos dados de plataformas e redes sociais dos nossos
              clientes.
            </style.TextBox>
          </style.BoxInfo>
          <style.BoxInfo>
            <style.IconApprovalApp />
            <style.TextBox>
              Monitoramos dados de plataformas e redes sociais dos nossos
              clientes.
            </style.TextBox>
          </style.BoxInfo>
          <style.BoxInfo>
            <style.IconApprovalApp />
            <style.TextBox>
              Monitoramos dados de plataformas e redes sociais dos nossos
              clientes.
            </style.TextBox>
          </style.BoxInfo>
          <style.BoxInfo>
            <style.IconApprovalApp />
            <style.TextBox>
              Monitoramos dados de plataformas e redes sociais dos nossos
              clientes.
            </style.TextBox>
          </style.BoxInfo>
          <style.BoxInfo>
            <style.IconApprovalApp />
            <style.TextBox>
              Monitoramos dados de plataformas e redes sociais dos nossos
              clientes.
            </style.TextBox>
          </style.BoxInfo>
        </style.ContainerBox>
      </style.ContainerGeral>
    </>
  );
}
