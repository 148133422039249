import React from 'react';
import * as style from './style';
import foto1 from '../../img/foto1.jpg';
import foto2 from '../../img/foto2.jpg';
import grupo6 from '../../img/grupo6.png';
export default function TabArtist() {
  return (
    <>
      <style.ContainerGeral>
        <style.TextSubTittle>ARTISTAS</style.TextSubTittle>
        <style.ContainerBox>
          <style.BoxInfo>
            <img src={foto1} alt="" height="230px" />
          </style.BoxInfo>
          <style.BoxInfo>
            <img src={foto2} alt="" height="230px" />
          </style.BoxInfo>
          <style.BoxInfo>
            <img src={foto1} alt="" height="230px" />
          </style.BoxInfo>
          <style.BoxInfo>
            <img src={foto2} alt="" height="230px" />
          </style.BoxInfo>
          <style.BoxInfo>
            <img src={foto1} alt="" height="230px" />
          </style.BoxInfo>
          <style.BoxInfo>
            <img src={foto2} alt="" height="230px" />
          </style.BoxInfo>
          <style.BoxInfo>
            <img src={foto1} alt="" height="230px" />
          </style.BoxInfo>
          <style.BoxInfo>
            <img src={foto2} alt="" height="230px" />
          </style.BoxInfo>
          <style.BoxInfo>
            <img src={foto1} alt="" height="230px" />
          </style.BoxInfo>
          <style.BoxInfo>
            <img src={foto2} alt="" height="230px" />
          </style.BoxInfo>
        </style.ContainerBox>
        <style.TextExperts>Somos experts em:</style.TextExperts>
        <img src={grupo6} alt="" height="165px" />
        <div style={{ height: '8rem' }}></div>
      </style.ContainerGeral>
    </>
  );
}
