import React from 'react';
import * as style from './style';
import one from '../../img/one.jpg';
import two from '../../img/two.jpg';
import tree from '../../img/tree.jpg';
import TabWork from '../../Components/TabWork';
import { Parallax } from 'react-scroll-parallax';
import useWindowSize from '../../Hooks/useWindowSize';
import TabArtist from '../../Components/TabArtist';
import TabJoin from '../../Components/TabJoin';

// import two from '../../img/one.png';
// import tree from '../../img/one.png';
export default function Home() {
  const { width } = useWindowSize();

  return (
    <style.Container>
      <Parallax speed={-40} style={{ width: '100%', zIndex: 2 }}>
        <img src={one} width={width - 23} />
      </Parallax>
      <TabWork />
      <Parallax
        speed={-50}
        style={{ width: '100%', zIndex: 1, marginTop: '9rem' }}
      >
        <img src={two} width={width - 23} />
      </Parallax>
      <TabArtist />
      <Parallax
        speed={-50}
        style={{ width: '100%', zIndex: 0, marginTop: '9rem' }}
      >
        <img src={tree} width={width - 23} />
      </Parallax>
      <TabJoin />
      <style.Footer>
        <style.TextFooter>Juan Amaral</style.TextFooter>
      </style.Footer>
    </style.Container>
  );
}
