import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  //min-width: 100vw;
  background-color: black;
  flex-direction: column;
`;

export const BoxAbout = styled.div`
  color: #141414;
`;
export const ContainerOneImg = styled.div`
  position: absolute;
  height: auto;
  width: 100%;
  min-height: '550px';
  z-index: 0;
`;
export const Footer = styled.div`
  background: black;
  height: 5rem;
  width: 100%;
  z-index: 3;
`;
export const TextFooter = styled.p`
color: white;
font-size: 1rem;
font-family: Arial, Helvetica, sans-serif;
text-justify: center;
text-align: center;

`
