import styled from 'styled-components';
import {ConnectWithoutContact} from '@styled-icons/material-twotone/ConnectWithoutContact';
import {TargetArrow} from '@styled-icons/fluentui-system-regular/TargetArrow';
import {CodeBlock} from '@styled-icons/boxicons-regular/CodeBlock';
import {ApprovalApp} from '@styled-icons/fluentui-system-filled/ApprovalApp';
import '../../App';

export const ContainerGeral = styled.div`
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background:transparent;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position:absolute;
    margin-top: 240rem;
    z-Index: 2;
`;

export const ContainerBox = styled.div`
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 20rem));
    //object-fit: cover;
    grid-gap: 1rem;
    background:transparent;
    width: 50%;
    margin-top: 2rem;
    margin-bottom: 3rem;
    //position: relative;

`;
export const BoxInfo = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;
    height: 25rem;
    padding: 0rem 2rem 0rem 2rem;
`;
export const TittleBox = styled.h1`
  font-size: 2rem;
  font-weight: 100;
  font-family: Arial, Helvetica, sans-serif;
`;
export const SubTittleBox = styled.h1`
  font-size: 2rem;
`;

export const TextBox = styled.p`
    color: #525252;
    font-size: 1.3rem;
    font-weight: 400;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    wrap: wrap;
    
`;
export const TextTittle = styled.h1`
    font-family: 'Rubik';
    font-weight: 900;
    margin-top: -20.5rem;
    font-size: 2.5rem;
    color: #141414;
    position: relative;
    @media(max-width: 400px){
        font-size: 7rem;
        margin-top: 43.6rem;
    }
    /* -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
    @keyframes glow {
    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
    }
    to {
        text-shadow: 0 0 40px #fff, 0 0 60px #ff4da6, 0 0 80px #ff4da6, 0 0 100px #ff4da6, 0 0 120px #ff4da6, 0 0 140px #ff4da6, 0 0 160px #ff4da6;
    }
  } */

`;

export const TextSubTittle = styled.h1`
  margin-top: -10.5rem;
  font-size: 1.5rem;
  font-family: 'Courier New', Courier, monospace;
  width: 30%;
  text-align: center;
  color: white;
  /* color: red; */
  position: relative;
  wrap:wrap;
  @media(max-width: 400px){
      font-size: 7rem;
      margin-top: 43.6rem;
  }

`;




//Icons
export const IconConnectWithoutContact = styled(ConnectWithoutContact)`
    width: 5rem;
    color: white;
`;
export const IconTargetArrow = styled(TargetArrow)`
    width:5rem;
    color: white;
`;
export const IconCodeBlock = styled(CodeBlock)`
    width:5rem;
    color: white;
`;
export const IconApprovalApp = styled(ApprovalApp)`
    width:5rem;
    color: white;
`;

